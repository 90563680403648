<template>
  <div class="user-agreement">
    <div class="agreement-box">
      <h3 class="title">用户协议</h3>
      <div class="agreement-msg">
        <p>中国经济信息社“新华财经”路演系统用户协议</p>
        <p>本协议发布（更新）于 2019 年 11月 19 日</p>
        <p>特别提示：</p>
        <p>【条款接受】： 您与中国经济信息社（定义如下）签署以下服务条款（以下简称“协议”）。中国经济信息社向您提供“新华财经”路演系统服务及任何相关应用程序、服务或网页的使用权和访问权（统称“服务”）。您必须先同意本协议，然后才能访问或使用本服务。在注册使用“新华财经”路演系统服务前，请您仔细阅读本协议的全部条款，当您在相关页面点击“注册”时或实际使用“新华财经”路演系统服务，即表示您同意本协议的全部条款，协议成立并生效；如您不同意本协议的任何条款，请勿点击“注册”或使用本终端。本终端的其他相关页面对服务及双方权利义务的描述不明确，或与本协议矛盾冲突的，以本协议为准。您应该打印或通过其他方式保存一份协议的副本留作记录，并在使用本服务时参阅协议条款内容。 </p>
        <p>“中国经济信息社”指和您签署协议的开发并运营“新华财经”路演系统之公司实体（以下简称“本公司”）。 </p>
        <p>如果您在任何时候对任何内容存在疑虑或异议，请您立即联系我们，以便我们对相关内容进行解释和说明，使您能够充分理解，从而帮助您决定是否接受本协议及使用“新华财经”路演系统的服务。 </p>
        <p>【重要提示】： 请仔细阅读以下条款，只要您使用或访问本服务，即表示您接受这些条款并构成有法律约束力的协议。如果您希望使用或访问本服务中的任何一项，都必须接受这些条款。如果您选择接受这些条款，那么您必须严格按照条款所述行事；中国经济信息社不接受您对条款的任何更改（增减）。本公司有权随时对协议的条款做出变更、修改以及添加或删减。如协议发生任何变更，则以变更后的条款为准，其效力自动覆盖您首次访问“新华财经”路演系统时接受的条款。您确认并同意，如果您在协议发生变更后仍继续使用本服务，即表示您接受修改后的协议。在不影响上述规定的情况下，如果本公司对协议做出的变更会给用户使用本服务造成实质影响，本公司可能会在适用的公司网站或“新华财经”发布此类变更的相关通知，并且/或者以电子邮件形式通知您此类变更。</p>
        <p>1、同意与保证</p>
        <p>1.1用户确认并保证，作为自然人用户注册时，您已年满18岁并具有完全民事行为能力；如作为机构用户注册时，用户是依据中华人民共和国（不包含香港、澳门、台湾地区）法律登记注册并合法开展经营活动或其他活动的法人或其他组织。如用户与前述条件不符的，用户应立即停止注册或使用本服务。如您未能符合和始终遵守协议，可能会导致您无法再使用本服务且/或您的协议被终止。 </p>
        <p>1.2请用户注意，因本协议为格式条款，为公平起见，本公司将本公司认为与用户的权利存在或可能存在重大关系或对本公司可能具有免责或限制责任的条款进行加粗显示（粗体字）。用户确认，在注册成为本终端用户以接受或使用本公司服务前，用户已充分阅读、理解并接受本协议的全部内容。 </p>
        <p>1.3用户保证，在任何时候不会将“新华财经”路演系统的服务用于任何非法目的，比如侵害他人合法权益，或者以非法方式使用我们的服务。</p> 
        <p>1.4用户保证，不会授权他人使用我们的服务。比如用户将使用本协议项下服务所需的载体，包括但不限于用于登录的账户、手机等披露或提供给其他人代替操作、使用我们的服务。上述操作可能导致他人冒用您的身份使用本协议项下服务，因此给您造成损失或导致不良行为记录。 </p>
        <p>1.5 用户同意，如果用户违反以上保证或本公司认为继续提供服务将会导致本公司或第三方的合理利益受到损失，本公司有权限制或终止向您提供本协议项下的部分或全部服务。 </p>
        <p>1.6用户同意，在本终端服务终止后，为了后续异议或纠纷处理及未来查询相关信息的需要，“新华财经”路演系统仍可继续保留您在使用本公司服务期间形成的所有信息和数据。</p>
        <p>2、服务内容及服务费用</p>
        <p>2.1本协议项下的服务是指本公司通过“新华财经”路演系统向用户提供财经资讯、行情数据、互动活动等服务，除非另有明确规定，增强或强化目前服务的任何新功能，包括新产品以及新增加的服务，均无条件地适用本协议。 </p>
        <p>2.2本公司有权对“新华财经”路演系统上提供的产品和服务的具体内容进行补充和调整。 </p>
        <p>2.3在用户使用本服务时，本公司有权向用户收取服务费用，服务费用标准将在“新华财经”路演系统上公布。本公司保留调整服务费用的权利，且可根据实际情况对部分免费服务开始收费，前述修改、变更或开始收费前，新华财经将在相应服务页面进行通知或公告。如果您不同意上述修改、变更或付费内容，可停止使用该服务。 </p>
        <p>2.4服务费用按照购买日公布的价格及优惠政策执行，用户应在本协议签订当日支付全额终端服务费用。 </p>
        <p>2.5终端服务期限自软件账号激活之日起计算。如用户在免费试用期间通过缴费购买“新华财经”路演系统服务，付款成功后免费试用有效期立即结束，即开始计算付费服务期。 </p>
        <p>2.6基于服务事项的特点，请您在接受服务之时谨慎考虑，一旦您接受“新华财经”路演系统服务并支付相应费用，则服务费不予退还。 </p>
        <p>2.7.服务期限届满后，用户愿意继续使用“新华财经”路演系统服务的，应办理续期手续并交纳相应的续期费用。若届时双方没有特殊约定的，在续期期间内用户与公司之间的权利义务关系继续适用本协议的约定。 </p>
        <p>2.8. 用户须知道本公司仅提供与“新华财经”路演系统有关的网络服务，除此之外与网络服务有关的设备（包括但不限于个人电脑、手机、及其他与接入互联网或移动网有关的装置）及所需要的费用（包括但不限于为接入互联网而支付的电话费及上网费、为使用移动网而支付的手机费）均应由用户自行承担。</p>
        <p>2.9 本公司有权判断用户的行为是否符合本终端服务协议条款的规定，如果本公司认为用户违背了协议条款的规定，本公司有终止向其提供“新华财经”路演系统服务的权利，本公司并有权据此解除本协议且不承担任何责任。</p>
        <p>3、注册及信息采集授权</p>
        <p>3.1用户在注册账户时，我们会要求提供您的个人信息，包括您的姓名、邮件地址、电话号码、电子邮件地址及银行账户信息。您不是一定要提供我们要求的个人信息，但在许多情况下，如果您选择不提供，我们将无法为您提供服务，或回应您提出的任何问题。 </p>
        <p>3.2用户同意，本客户端基于履行本协议或为您提供服务使用您的个人信息，使用情形包括但不限于验证身份、识别用户；为用户提供服务、发送服务介绍或活动预告、收取服务费用；以及发送重要的通知，如协议条款变更、收费标准变更等。 </p>
        <p>3.3本终端非常重视对您的信息保护，将严格保护您的个人信息，本终端将依照法律法规与《隐私政策》及其他与您的约定保护您的信息。</p>
        <p>3.4用户须根据客户端要求提供及时、准确的资料，如资料有任何变动，须及时更新。本公司将依据您注册信息中的联系方式与您进行及时、有效的联系。因注册资料不真实而引起的问题及后果，本公司不承担任何责任。</p>
        <p>3.5用户注册后将得到一个账户，用户应妥善保管账号信息，包括但不限于用户名、账号、密码。如因用户保管账户信息不善，或因用户不慎登录“钓鱼”终端、互联网邮箱出现安全问题，导致他人获得用户信息，或客户端服务被他人取得，或错误提交授权等造成的损失及责任由用户承担。如因用户未保管好其账户而给本公司或第三方造成损失的，用户应当赔偿本公司及第三方因此而造成的损失，消除影响，并使本公司及第三方不受损害。 </p>
        <p>3.6用户在此承诺，利用账户所进行的各项活动，包括但不限于用户上传的资讯、资料、文字、照片、图形、信息或其它资料（以下简称“资料”）均符合相关法律法规之规定，且不得侵犯他人的合法权益，若用户利用本客户端服务从事任何违法或侵权行为，由用户自行承担全部责任，因此给本公司或任何第三方造成任何损失，用户依法承担相应赔偿责任，并使本公司免受由此产生的任何损害。用户账户遭到未授权的使用或发生其他任何安全问题，用户应当立即通知本公司，并且用户在每次连线结束，应结束账户使用，否则用户可能得不到本客户端的安全保护。</p>
        <p>4、使用及服务准则</p>
        <p>4.1用户在访问、注册或使用本终端的服务时，本公司严格保守用户的个人信息、以及通过各种技术手段和强化内部管理等办法提供保护服务功能，保护的个人信息等安全。未经用户授权，本终端不会向第三方提供您的个人信息，但以下情况除外： </p>
        <p>4.1.1根据有关法律法规的要求； </p>
        <p>4.1.2按照相关政府主管部门的要求； </p>
        <p>4.1.3为维护社会公众利益； </p>
        <p>4.1.4为维护本公司的合法权益，但在此情形下限于法院、仲裁机构或其他政府机关。</p>
        <p>4.2用户通过本终端获得的相关服务等，供用户参考，本公司不对用户依据本公司提供的服务等作出的任何商业决策或投资决策承担任何责任。</p>
        <p>4.3用户通过本终端获得的服务等，未经本公司书面同意不得以任何形式全部或部分提供给任何第三方。 </p>
        <p>4.4如果用户对于本终端的服务有异议，可以向本终端提出，本终端将根据法定期限和程序进行核查、处理及答复。</p>
        <p>5、服务变更、中断和账户注销</p>
        <p>5.1鉴于终端服务的特殊性，用户同意本公司有权随时变更、中断或终止本终端部分或全部的服务。如变更、中断或终止的终端服务属于免费服务，本公司会尽可能地但不是必须通知用户，且无需对任何用户或任何第三方承担任何责任。</p> 
        <p>5.2在服务期内，公司将有权根据经营活动的需要，随时对终端的服务内容进行更新、增加或删除，且无需另行通知或取得用户的同意。 </p>
        <p>5.3公司尽力提供完整、及时、准确的信息，但不对所提供信息的完整性、及时性、准确性承担任何责任。</p>
        <p>5.4在用户不需要继续使用本服务时，可以申请注销您的账户。在用户的账户注销后，用户在本协议下的权利义务终止（本协议或法律另有规定不能终止的除外）；但为使本公司可以按法律法规的规定处理信息主体或第三方提出的关于用户账户注销之前相关信息的异议申请，用户同意本公司在用户的账户注销后仍可继续持有、保留用户的相关信息和服务使用记录，否则将有可能损害用户的合法权益，或导致本公司不能遵守法律、行政法规的要求。</p>
        <p>6、知识产权</p>
        <p>6.1本公司名称标识以及本服务所涉及的其他商标、服务商标、图形和标识均为 本公司在中国和/或其他国家或地区的商标或注册商标。本服务所涉及的其他商标、服务商标、图形和徽标可能是其各自权利人的商标。除非本协议中另有说明，否则您并未取得前述任何商标的专有或非专有使用许可，并且您还同意，您不得移除、遮挡或篡改本服务随附或包含的任何专有权通知（包括商标和版权通知）。 </p>
        <p>6.2您同意，本公司是“新华财经”路演系统的拥有者和运营者，用于实施本服务的服务、图形、用户界面、音频剪辑、编辑内容、脚本和软件（包括但不限于任何本终端内容的设计、架构、选择、搭配、表达、外观和风格以及排列形式）均包含专有信息和资料，且这些专有信息和资料归本公司和/或本公司的关联企业或第三方提供商所有，并且受到相关知识产权保护法律和其他法律（包括但不限于版权法）的保护。您同意，除了根据本协议的规定为使用本服务使用上述专用信息或资料外，您不会将上述专有信息或资料用作其他任何用途。除非本协议明确允许，否则本服务的任何部分均不得以任何形式或手段进行复制。您同意不会以任何方式修改、租借、复制、转载、编辑、销售、分发或制作基于本服务产生的衍生作品，并且不会以任何未授权形式利用本服务不当牟利（包括但不限于擅用网络容量或导致网络容量负载增加）。 即使本协议中存在任何其他条款，本公司及其委托方仍有权随时更改、暂停、撤销或停用任何用户对本服务、内容或者包含本服务部分内容的其他资料的访问权限，恕不另行通知。在任何情况下，本公司都无需为上述变更负责。本公司可能还会对本服务特定功能或内容设置使用或访问设限，恕不另行通知，本公司也无需承担任何责任。 </p>
        <p>6.2 用户保证，其通过账户向本公司所提供的资料不存在任何侵犯他人知识产权的风险，因用户上传的资料侵犯他人等知识产权而导致的法律责任由用户承担。</p>
        <p>7、违约责任</p>
        <p>7.1任何一方未履行本协议项下的任何一项条款均被视为违约。违约方应承担因自己的违约行为而给另一方造成的经济损失。 </p>
        <p>7.2如因一方行为侵害第三方合法权益，侵权方应负责处理并承担所有责任。因此给另一方造成损失的，侵权方应承担赔偿责任。</p>
        <p>8、免责声明与责任限制</p>
        <p>8.1下列非本公司责任导致的不良后果，本公司不承担责任： </p>
        <p>8.1.1用户因账号管理失误或不当而导致的任何不良后果。如用户发现任何非法使用用户账号或安全漏洞的情况，请立即与本终端联系。 </p>
        <p>8.1.2用户因不当使用终端服务产品而导致的任何不利后果。 </p>
        <p>8.1.3因第三方原因提供的信息不准确、不完整等所导致的任何不利后果。 </p>
        <p>8.2用户使用本公司服务时，若有任何违反国家法律法规或侵犯任何第三方的合法权益的情形时，本公司有权直接删除该等违反规定之信息，并可以暂停直至终止向用户提供服务。 </p>
        <p>8.3本终端在下列状况发生时会无法正常运作，我们无法向用户提供服务。因此给用户造成的各种影响或损失，本公司不承担责任： </p>
        <p>8.3.1我们公告的停机维护期间。 </p>
        <p>8.3.2电信设备出现故障不能进行数据传输的。</p>
        <p>8.3.3发生台风、地震、海啸、洪水、停电、战争、恐怖袭击等事件造成我们“新华财经”终端障碍不能提供服务的。 </p>
        <p>8.3.4公司全力保障信息的优质传输，但对因证券交易所、卫星传输线路、电信部门及网络服务提供商、上市公司信息提供商等任何原因造成的信息传递异常情况所致后果不承担任何法律责任。且“新华财经”路演系统服务将尽力维护其安全性及方便性，但对服务中出现信息删除或储存失败不承担任何负责。 </p>
        <p>8.4由于技术水平限制及可能存在的各种恶意手段，有可能因本终端可控范围外的因素，包括但不限于不可抗力、黑客袭击、系统故障、通讯故障、供电系统故障、手机病毒、恶意程序攻击及其他不可归因于本终端的情况而导致用户信息泄露的，本终端不承担责任；但如因本终端故意或重大过失导致您的信息泄露并给您造成直接损失的，本终端依法承担赔偿责任。若不幸发生个人信息安全事件，本终端将按照法律法规的要求，及时向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议等。我们将及时将事件相关情况以邮件、信函、电话等通知方式或发布公告的方式告知您。</p> 
        <p>8.5 用户同意，在某种程度上，任何商业决策或投资决策都意味着一定的风险，本公司不应也不能以任何方式担保客户的风险，因此本终端对用户由于使用本终端所提供的信息可能遭受的任何损失不承担责任。 </p>
        <p>8.6除法律另有规定的除外，在任何情形下，本公司对任何间接、衍生、偶然性的、惩罚性的损失，包括但不限于商业机会的损失、利润的损失、商誉损失等，不承担责任，即使本公司已被事先告知该等损失的可能性。 </p>
        <p>8.7尽管本协议有其他规定，如果任何因用户使用“新华财经”路演系统服务而产生的或以任何方式与之相关的损害或损失，本公司应向用户承担的责任，在任何情况下不超过本公司就单个违约事项向用户收取的服务费用。</p>
        <p>9、协议变更和终止</p>
        <p>本公司将根据国家法律、法规及其他规范性文件的规定变化，增加、变更或终止部分或全部服务，并据此为进一步明确协议双方的权利义务的需要而对本协议进行变更。同时，用户同意未来我们可以通过本终端、客户端或其他适当的地方进行公布的方式通知用户，而不需要另行单独通知用户协议发生变更。用户应在本协议内容公告变更后充分阅读、理解变更后的协议内容，用户在协议内容变更后继续使用本终端服务的，表示您已充分阅读、理解并接受变更后的协议内容；若用户不同意修改后的协议内容，应停止使用本终端服务。</p>
        <p>10、其他约定</p>
        <p>10.1通知：本公司向用户发出的通知，采用电子邮件、页面公告等形式。本协议条款的修改或其他事项变更时，本公司将会以上述某一形式进行通知。该通知于发送之日视为已送达收件人。 </p>
        <p>10.2不可抗力:本协议所指不可抗力，是指不能预见、不能避免并不能克服的客观情况。由于不可抗力事件，致使一方在履行其在本协议项下的义务过程中遇到障碍或延误，不能按规定的条款全部或部分履行其义务的，遇到不可抗力事件的一方（“受阻方”），只要满足下列所有条件，不应视为违反本协议：</p> 
        <p>10.2.1受阻方已尽最大努力履行其义务并减少由于不可抗力事件给另一方造成的损失； </p>
        <p>10.2.2不可抗力事件发生时,受阻方已立即通知对方，并在不可抗力事件发生后的十五(15)天内提供有关该事件的公证文书和书面说明，书面说明中应包括对延迟履行或部分履行本协议的原因说明。 </p>
        <p>10.2.3不可抗力事件终止或被排除后，受阻方应继续履行本协议，并应尽快通知另一方。受阻方应可延长履行义务的时间，延长期应相当于不可抗力事件实际造成延误的时间。 </p>
        <p>10.3法律适用及争议解决 本协议相关的所有问题均受到中华人民共和国（不包含香港、澳门、台湾地区）法律管辖。如果因为本协议产生任何争议，双方同意由本公司住所地人民法院管辖及裁决。 </p>
        <p>10.4如本协议中任何条款因任何原因而全部或部分无效，本协议等其他条款仍继续有效。 </p>
        <p>10.5本协议未尽事宜，根据中华人民共和国相关法律法规及本公司业务规定办理。 </p>
        <p>10.6本协议自用户完成注册时成立并生效。</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  
}
</script>
<style lang="less" scoped>
.user-agreement{
  margin: 0 auto;
  padding: 80px 0;
  width: 1200px;
  .agreement-box{
    margin: 0 auto;
    padding: 30px 20px;
    width: 960px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    .title{
      text-align: center;
      color: #0A0A0A;
    }
    .agreement-msg{
      margin-top: 30px;
      font-size: 16px;
      color: #0A0A0A;
      p{
        margin-bottom: 10px;
        text-indent: 2em;
      }
    }
  }
}
</style>